<template>
  <div class="page">
    <div class="communityElections">
      <div class="item" @click="toDetail()">
        <div class="item-l">
          <img :src="detail.pictureUrl" />
        </div>
        <div class="item-r">
          <div class="name">{{ detail.electionName }}</div>
          <div class="endTime" v-if="detail.electionStatus == 2">
            结束时间：{{ detail.publicityEndTime }}
          </div>
          <div class="item-r-b">
            <div class="status-ing" v-if="detail.electionStatus == 1">
              进行中
            </div>
            <div class="status-end" v-else>已结束</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 选举一般流程 -->
    <div class="processBox">
      <div class="title">选举一般流程</div>
      <div
        class="stepBox"
        v-for="(item, index) in detail.detailsList"
        :key="index"
      >
        <div class="ponit12"></div>
        <div class="ponit16"></div>
        <div
          class="emptyPoint"
          v-if="
            index + 1 == detail.detailsList.length && detail.electionStatus == 1
          "
        ></div>
        <div class="orangePoint" v-else></div>
        <div class="name" v-show="index == 0">步骤1：换届公告步骤</div>
        <div class="name" v-show="index == 1">
          步骤2：选举办法、业主大会议事规则及管理 公约（拟订草案）
        </div>
        <div class="name" v-show="index == 2">
          步骤3：关于征集业主委员会委员候选人的告
          示及修订后议事规则及管理公约修订稿
        </div>
        <div class="name" v-show="index == 3">
          步骤4：关于召开业主大会会议的通知
        </div>
        <div class="name" v-show="index == 4">步骤5：候选人展览</div>
        <div class="name" v-show="index == 5">
          步骤6：关于业委会委员选举结果和业主大会 议事规则修订表决结果的公示
        </div>
        <div class="name" v-show="index == 6">步骤7：业委会第一次会议</div>
        <div class="name" v-show="index == 7">步骤8：公告并备案</div>
        <div class="time">结束日期：{{ item.publicityEndTime }}</div>
        <div class="file">
          <div class="file-l">公式文件：</div>
          <div class="file-r" @click="openFile(item)">
            {{ item.fileName }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { pdfOrigin } from "../../../../public/config/request.js";
import wx from "weixin-js-sdk";
import { getStepInfoUrl } from "./api.js";
export default {
  name: "communityElectionsDetail",
  data() {
    return {
      electionId: null,
      detail: {},
    };
  },
  created() {
    this.electionId = this.$route.query.electionId;
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      let params = {
        electionId: this.electionId,
      };
      this.$axios.get(`${getStepInfoUrl}`, { params }).then((res) => {
        if (res.code == 200) {
          res.data.detailsList.forEach((item) => {
            let domain = item.fileUrl.split("/");
            item.fileName = domain[domain.length - 1];
          });
          this.detail = res.data;
        }
      });
    },
    openFile(item) {
      console.log(item.fileUrl);
      let domain = item.fileUrl.split("/");
      if (domain[2]) {
        domain = domain[0] + "//" + domain[2];
      } else {
        domain = "";
      }
      let url = item.fileUrl.replace(domain, window.location.origin);
      console.log(url);
      if (wx.miniProgram) {
        wx.miniProgram.navigateTo({
          url: `/pages/webview/index?url=${url}`,
        });
      }
    },
  },
};
</script>

<style scoped lang='less'>
.page {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background: #fafafa;
  padding: 30px 32px;
  .processBox {
    width: 100%;
    box-sizing: border-box;
    padding: 44px 60px 40px 42px;
    background: #ffffff;
    box-shadow: 0px 4px 32px 0px rgba(66, 114, 153, 0.1);
    border-radius: 16px;
    .stepBox {
      width: 100%;
      padding: 0 0 26px 24px;
      box-sizing: border-box;
      border-left: 2px solid #fe9514;
      position: relative;
      margin-bottom: 14px;
      .orangePoint {
        width: 16px;
        height: 16px;
        background: #fe9716;
        border-radius: 50%;
        position: absolute;
        top: 12px;
        left: -9px;
      }
      .emptyPoint {
        width: 16px;
        height: 16px;
        border: 2px solid #fe9514;
        background: #fff;
        border-radius: 50%;
        position: absolute;
        top: 12px;
        left: -9px;
        box-sizing: border-box;
      }
      .ponit12 {
        width: 12px;
        height: 12px;
        background: #fff;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: -9px;
      }
      .ponit16 {
        width: 16px;
        height: 16px;
        background: #fff;
        border-radius: 50%;
        position: absolute;
        top: 28px;
        left: -9px;
      }
      .name {
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 40px;
        padding-bottom: 14px;
      }
      .time {
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        padding-bottom: 2px;
      }
      .file {
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        display: flex;
        .file-l {
          white-space: nowrap;
        }
        .file-r {
          word-break: break-all;
          color: #0091ff;
        }
      }
    }
    .stepBox:nth-last-child(1) {
      border-left: 2px solid #fff;
    }
    .title {
      font-size: 28px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      padding-bottom: 36px;
    }
  }
  .communityElections {
    width: 100%;
    box-sizing: border-box;
    .item {
      width: 100%;
      height: 220px;
      background: #ffffff;
      box-shadow: 0px 4px 32px 0px rgba(66, 114, 153, 0.1);
      border-radius: 16px;
      overflow: hidden;
      display: flex;
      margin-bottom: 20px;
      .item-l {
        width: 224px;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .item-r {
        flex: 1;
        padding: 30px 0 0 28px;
        box-sizing: border-box;
        position: relative;
        .name {
          font-size: 32px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.85);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-bottom: 2px;
        }
        .endTime {
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
        }
        .item-r-b {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 62px;
          padding-left: 28px;
          box-sizing: border-box;
          .btn-ing {
            width: 194px;
            height: 62px;
            background: linear-gradient(180deg, #fdc76f 0%, #faad3d 100%);
            box-shadow: 0px 4px 8px 0px rgba(225, 225, 225, 0.5);
            border-radius: 16px 0px 16px 0px;
            font-size: 26px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 62px;
            text-align: center;
          }
          .btn-end {
            width: 194px;
            height: 62px;
            background: linear-gradient(180deg, #ededed 0%, #d8d8d8 100%);
            box-shadow: 0px 4px 8px 0px rgba(225, 225, 225, 0.5);
            border-radius: 16px 0px 16px 0px;
            font-size: 26px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.25);
            line-height: 62px;
            text-align: center;
          }
          .status-ing {
            width: 99px;
            height: 40px;
            background: #fff3eb;
            border-radius: 4px;
            border: 2px solid #ffc7a4;
            text-align: center;
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #fe6f16;
            line-height: 40px;
          }
          .status-end {
            width: 99px;
            height: 40px;
            background: rgba(0, 0, 0, 0.04);
            border-radius: 4px;
            border: 2px solid rgba(0, 0, 0, 0.25);
            text-align: center;
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
            line-height: 40px;
          }
        }
      }
    }
  }
}
</style>