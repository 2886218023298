// 获取下沉党员列表
const getListUrl = `/gateway/hc-govern/communistInfo/getList`;
// 获取资讯列表 26风采展示;27社区抽查;28制度公示;29下沉动态;30坚守初心;
const getTopicListURL = `/gateway/hc-neighbour/topic/getTopicList`;
// 投票问卷列表
const getQuTopicListUrl = `/gateway/hc-govern/miniapi/qu/topicList`;
// 用户信息接口
const userInfoUrl = `/gateway/hc-mini/user/mini/user-info`;
// 获取社区选举列表
const getGovernListUrl = `/gateway/hc-govern/communityElection/getList`;
// 获取步骤
const getStepInfoUrl = `/gateway/hc-govern/communityElection/getStepInfo`;
export {
  getListUrl,
  getTopicListURL,
  getQuTopicListUrl,
  userInfoUrl,
  getGovernListUrl,
  getStepInfoUrl,
};
